export const TimeOffSettingsInstructions = () => {
  return (
    <>
      <p>Fine tune your time off settings for the your 3 inputs:</p>
      <ul>
        <li>
          Annual Allowance: The number of days off you are entitled to each year
        </li>
        <li>Annual Cap: The maximum number of days you can accumulate over a year</li>
        <li>Current Balance: The number of days you already accrued as of now</li>
      </ul>
    </>
  );
};


export const PublicHolidaysSettingsInstructions = () => {
  return (
    <>
      <p>Interact with checkboxes to select or deselect your preferred public holidays <br />
        and make use of the Add holiday feature to include any missing holidays.
      </p>
    </>
  );
};
