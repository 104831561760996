import React from 'react';
import { Container } from 'reactstrap';

const PlanWithFriends = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h2>Plan with friends - COMING SOON</h2>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanWithFriends;
