export const pageTitle = 'Vacay - Optimize your time off';
export const defaultMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// Index for DateTime.getDay() method corresponding to week-ends
// Monday = index 0, Sunday = index 6
export const weekendDayIndex = [6, 0];

export const minSettingsValueDays = 0;

export const maxSettingsValueDays = 365;

export const barChartBorderRadius = 10;

export const barChartBarPercentage = 0.2;

export const PolicyTypes = {
  accrual: 'accrual',
  unlimited: 'unlimited',
};

export const unlimitedMinAxisDays = 10;
